import { useContext, useEffect, useState } from "react";
import { Prompt } from "react-router-dom";
import {
    useHistory,
    useParams
} from "react-router-dom";
import Field from "./Field";
import { firestore } from "./firebase";
import { UserContext } from "./providers/UserProvider";
import { toast } from "react-toastify";
import Section from "./Section";
import crypto from "crypto";

export default function Article() {
    const history = useHistory();

    const { role, user } = useContext(UserContext);

    const { id } = useParams();

    const [data, setData] = useState({});
    const [article, setArticle] = useState(null);

    const [unsavedChanges, setUnsavedChanges] = useState(false);

    useEffect(() => JSON.stringify(data) !== JSON.stringify(article?.data()) ? setUnsavedChanges(true) : setUnsavedChanges(false), [article, data]);

    useEffect(() => firestore.collection('articles').doc(id).get().then(document => {
        setData(document.data());
        setArticle(document);
    }), [id]);

    const save = async () => {
        data.agent = user.uid;
        if(!data.visibility) data.visibility = 'public';
        await firestore.collection('articles').doc(id).set(data, { merge: true });
        setUnsavedChanges(false);
        toast.success("Speichern erfolgreich", {theme: "dark"})
    };

    const remove = async () => {
        if(!window.confirm('Bist du dir sicher, dass du diesen Artikel löschen willst?')) return;
        await firestore.collection('articles').doc(id).delete();
        toast.success("Löschen erfolgreich", {theme: "dark"})
        history.push('/articles');
    }

    return <div>
        {/* <Prompt message="Einige Änderungen wurden noch nicht gespeichert. Trotzdem fortfahren?" when={unsavedChanges} /> */}
        <div className="bg-gray-500 flex flex-col md:flex-row gap-2 p-4 md:py-0 md:px-2">
            <button className="bg-blue-500 px-6 py-1 md:py-2 rounded md:rounded-b-none md:rounded-t md:mt-2 md:font-semibold text-white" onClick={save}>Änderungen speichern</button>
            <button className="bg-gray-400 px-6 py-1 md:py-2 rounded md:rounded-b-none md:rounded-t md:mt-2 md:font-semibold" onClick={remove}>Artikel löschen</button>
            <a href={"https://console.firebase.google.com/u/1/project/elgio-invited/firestore/databases/-default-/data/~articles~2F" + id} 
                target="_blank" rel="noreferrer"
                className="bg-gray-400 px-6 py-1 md:py-2 rounded md:rounded-b-none md:rounded-t md:mt-2 md:font-semibold flex items-center text-center"> 
                    In der Datenbank ansehen
            </a>
            <div className="bg-gray-500 px-6 py-1 md:py-2 rounded md:rounded-b-none md:rounded-t md:mt-2 md:font-semibold hidden md:block text-gray-500">a</div>
        </div>
        {
            id && article ? <div className="p-3 overflow-scroll" key={article.id}>
                <Section title="Metadaten">
                    <div className="flex-col md:flex-row flex my-1">
                        <div className="w-64">ID:</div>
                        <div className="w-64">{article?.id}</div>
                    </div>
                    <div className="flex-col md:flex-row flex my-1">
                        <div className="w-64">Agent:</div>
                        <div className="w-64">{article?.data()?.agent}</div>
                    </div>
                </Section>
                <Section title="Daten" openDefault>
                    <Field name="Sichtbarkeit" value={data?.visibility??'public'} type="dropdown" options={['public', 'private', 'blocked']} onChange={(val) => setData({...data, ...{visibility: val}})} />
                    <Field name="Titel" value={data?.title??''} type="text" onChange={(val) => setData({...data, ...{title: val}})} />
                    <Field name="Autor" value={data?.author??''} type="text" onChange={(val) => setData({...data, ...{author: val}})} />
                    <Field name="Tags" value={data?.tags} type="array-tags" onChange={(val) => setData({...data, ...{tags: val}})} />
                    <Field name="Bild" value={data?.image??''} type="image" fileDirectory={`article_images/${id}`} onChange={(val) => setData({...data, ...{image: val}})}/>
                </Section>
                <Section title="Abschnitte" openDefault>
                    {data?.sections?.map((section, index) => <ArticleSection key={index} section={section} onChange={(newSection) => {
                        const sections = data.sections;
                        sections[index] = newSection;
                        setData({...data, ...{sections}});
                    }} remove={() => {
                        const sections = data.sections;
                        sections.splice(index, 1);
                        setData({...data, ...{sections}});
                    }} />)}
                    <button onClick={() => setData({...data, ...{sections: [...(data?.sections??[]), {}]}})} className="bg-blue-500 text-white rounded px-2 py-3 font-semibold">Abschnitt hinzufügen</button>
                </Section>
                <button className="bg-blue-500 px-6 py-1 md:py-2 rounded md:rounded-t md:mt-2 md:font-semibold text-white" onClick={save}>Änderungen speichern</button>
            </div> : <div className="p-3">
                Bitte warten...
            </div>
        }
    </div>;
}

function ArticleSection({ section, onChange, remove }) {
    const { id } = useParams();

    return <div className="border border-gray-400 p-2 my-2">
        <Field name="Abschnitts-Art" value={section?.type??'text'} type="dropdown" options={['text', 'image', 'event', 'organizer', 'recurring_event']} onChange={(val) => onChange({...section, ...{type: val}})} />
        <Field name="Titel" value={section?.title??''} type="text" onChange={(val) => onChange({...section, ...{title: val}})} />
        {section?.type === 'text' && <Field name="Text" value={section?.text??''} type="textarea" onChange={(val) => onChange({...section, ...{text: val}})} />}
        {section?.type === 'text' && <Field name="Farbe" value={section?.color??''} type="color" onChange={(val) => onChange({...section, ...{color: val}})} />}
        {section?.type === 'image' && <Field name="Bild" value={section?.image??''} type="image" fileDirectory={`article_images/${id}`} onChange={(val) => onChange({...section, ...{image: val}})} />}
        {section?.type === 'event' && <Field name="Event" value={section?.event??''} type="event" onChange={(val) => onChange({...section, ...{event: val}})} />}
        {section?.type === 'organizer' && <Field name="Veranstalter/Club" value={section?.organizer??''} type="organizer" onChange={(val) => onChange({...section, ...{organizer: val}})} />}
        {section?.type === 'recurring_event' && <Field name="Eventreihe" value={section?.recurring_event??''} type="recurring_event" onChange={(val) => onChange({...section, ...{recurring_event: val}})} />}
        <button onClick={remove} className="bg-red-500 text-white rounded px-2 py-3 font-semibold">Abschnitt löschen</button>
    </div>;
}